import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Utils from '../../../utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 20px;
  background-color: ${$.color.white};
  border-radius: 35px;
  box-shadow: ${$.dropShadow.normal};
  flex: 1 0 400px;

  // NOTE: Mobile and above
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - 40px);
    margin-bottom: 20px;
  }
  // #endregion
`;

const Logo = styled.div`
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.apple-consultant > img {
    width: 210px;
  }
  &.aws-partner > * {
    width: 130px;
  }
  &.imda > img {
    width: 200px;
  }
  &.google > img {
    width: 200px;
  }
`;

const Desc = styled.div`
  color: ${$.color.black2};
  font-size: 15px;
  line-height: 18px;
  font-family: Lato Light;
  flex-grow: 2;
  text-align: center;
  margin-bottom: ${$.layout().margin4}px;
`;

const Card = ({ img, className, description, alt }) => (
  <Container>
    <Logo className={className}>
      {img.endsWith('.svg') ? (
        Utils.importOneSVG(img)
      ) : (
        <img src={Utils.importOneImage(img)} alt={alt} />
      )}
    </Logo>
    <Desc>{description}</Desc>
  </Container>
);

Card.defaultProps = {
  img: '',
  className: '',
  description: '',
  alt: '',
};

Card.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

export default Card;
